import React from 'react';
import ReactDOM from 'react-dom';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import 'bootstrap/dist/css/bootstrap.min.css'
import './index.css';
import App from './App';
import 'font-awesome/css/font-awesome.min.css'
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { StoreProvider } from './Store';

ReactDOM.render(
  <BrowserRouter>
    <StoreProvider>
      <HelmetProvider>
        <PayPalScriptProvider deferLoading={true}>  { /* DEFERLOADING true means that paypal should not be loaded at the beginning of the application */}
          <App />
        </PayPalScriptProvider>
      </HelmetProvider>
    </StoreProvider>
  </BrowserRouter>,
  document.getElementById('root')
);


/*
import { Provider } from 'react-redux';
import store from './store';

ReactDOM.render(
  <Provider store={store}>
      <App />
  </Provider>,
);
serviceWorker.unregister();
*/

