import React, { useContext, useEffect, useRef, useState } from 'react';
import socketIOClient from 'socket.io-client';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import ListGroup from "react-bootstrap/ListGroup";
import Badge from "react-bootstrap/Badge";
import Button from 'react-bootstrap/Button';
import MessageBox from '../components/MessageBox';
import { Store } from '../Store';
import { Helmet } from 'react-helmet-async';

let allUsers = [];
let allMessages = [];
let allSelectedUser = {};

const ENDPOINT =
  window.location.host.indexOf('localhost') >= 0
    ? 'https://supam-off-be.onrender.com'
    // ? 'http://127.0.0.1:7000'
    : window.location.host;

export default function SupportScreen() {

  const [selectedUser, setSelectedUser] = useState({});
  const [socket, setSocket] = useState(null);
  const uiMessagesRef = useRef(null);
  const [messageBody, setMessageBody] = useState('');
  const [messages, setMessages] = useState([]);
  const [users, setUsers] = useState([]);

  const { state } = useContext(Store);
  const { userInfo } = state;

  console.log('messages', messages);

  useEffect(() => {
    if (uiMessagesRef.current) {
      uiMessagesRef.current.scrollBy({
        top: uiMessagesRef.current.clientHeight,
        left: 0,
        behavior: 'smooth',
      });
    }

    if (!socket) {
      const sk = socketIOClient(ENDPOINT);
      setSocket(sk);
      sk.emit('onLogin', {
        _id: userInfo._id,
        name: userInfo.name,
        isAdmin: userInfo.isAdmin,
      });

      sk.on('message', (data) => {
        if (allSelectedUser._id === data._id) {
          allMessages = [...allMessages, data];
          console.log('selectMessage', allMessages)

        } else {
          const existUser = allUsers.find((user) => user._id === data._id);
          if (existUser) {
            allUsers = allUsers.map((user) =>
              user._id === existUser._id ? { ...user, unread: true } : user
            );
            setUsers(allUsers);
          }
        }
        setMessages(allMessages);
      });

      sk.on('updateUser', (updatedUser) => {
        const existUser = allUsers.find((user) => user._id === updatedUser._id);
        if (existUser) {
          allUsers = allUsers.map((user) =>
            user._id === existUser._id ? updatedUser : user
          );
          setUsers(allUsers);
        } else {
          allUsers = [...allUsers, updatedUser];
          setUsers(allUsers);
        }
      });
      sk.on('listUsers', (updatedUsers) => {
        allUsers = updatedUsers;
        setUsers(allUsers);
      });
      sk.on('selectUser', (user) => {
        allMessages = user.messages;
        console.log('selectUser', allMessages)
        setMessages(allMessages);
      });
    }
  }, [messages, socket, users, userInfo]);

  const selectUser = (user) => {
    allSelectedUser = user;
    setSelectedUser(allSelectedUser);
    const existUser = allUsers.find((x) => x._id === user._id);
    if (existUser) {
      allUsers = allUsers.map((x) =>
        x._id === existUser._id ? { ...x, unread: false } : x
      );
      setUsers(allUsers);
    }
    socket.emit('onUserSelected', user);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (!messageBody.trim()) {
      alert('Error. Please type message.');
    } else {
      allMessages = [
        ...allMessages,
        { body: messageBody, name: userInfo.name },
      ];
      setMessages(allMessages);
      setMessageBody('');
      setTimeout(() => {
        socket.emit('onMessage', {
          body: messageBody,
          name: userInfo.name,
          isAdmin: userInfo.isAdmin,
          _id: selectedUser._id,
        });
      }, 1000);
    }
  };




  return (
    <Row >
      <Helmet>
        <title>Customer Support - SUPAMART GHANA</title>
      </Helmet>
      <Col md={3}>
        {users.filter((x) => x._id !== userInfo._id).length === 0 && (
          <MessageBox>No User Found</MessageBox>
        )}
        <ListGroup>
          {users
            .filter((x) => x._id !== userInfo._id)
            .map((user) => (
              <ListGroup.Item
                action
                key={user._id}
                varaint={user._id === selectedUser._id ? 'info' : ''}
                onClick={() => selectUser(user)}
              >
                <Badge
                  bg={
                    selectedUser.name === user.name
                      ? user.online
                        ? "success"
                        : "secondary"
                      : user.unread
                        ? "danger"
                        : user.online
                          ? "success"
                          : "secondary"
                  }
                >
                  {selectedUser.name === user.name
                    ? user.online
                      ? "Online"
                      : "Offline"
                    : user.unread
                      ? "unread"
                      : user.online
                        ? "Online"
                        : "Offline"}
                </Badge>
                &nbsp;
                {user.name}
              </ListGroup.Item>
            ))}
        </ListGroup>
      </Col>

      <Col md={9} >
        <div className='admin'>

          {!selectedUser._id ? (
            <MessageBox>Select a user to start chat</MessageBox>
          ) : (
            <div>
              <Row>
                <h2 className='text-primary'>Chat with {selectedUser.name} </h2>
              </Row>
              <ListGroup ref={uiMessagesRef}>
                {messages.length === 0 && (
                  <ListGroup.Item>No message.</ListGroup.Item>
                )}
                {messages.map((msg, index) => (
                  <ListGroup.Item key={index} className='no-border'>
                    <span className={msg.isAdmin === true ? 'sender' :'customer'}>{msg.body}</span>
                  </ListGroup.Item>
                ))}
              </ListGroup>
              <div>
                <form onSubmit={submitHandler}>
                  <InputGroup>
                    <FormControl
                      type="text"
                      value={messageBody}
                      onChange={(e) => setMessageBody(e.target.value)}
                      placeholder="Message"
                      aria-describedby="button-search"
                    />
                    <Button type="submit">Send</Button>
                  </InputGroup>
                </form>
              </div>
            </div>
          )}
        </div>
      </Col>
    </Row>
  );
}
