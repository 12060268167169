import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import HomeScreen from './screens/HomeScreen';
import ProductScreen from './screens/ProductScreen';
import Container from 'react-bootstrap/Container';
import Badge from 'react-bootstrap/Badge';
import Nav from 'react-bootstrap/Nav';
import { useContext, useEffect, useState } from 'react';
import { Store } from './Store';
import CartScreen from './screens/CartScreen';
import SigninScreen from './screens/SigninScreen';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Navbar from 'react-bootstrap/Navbar';
import { LinkContainer } from 'react-router-bootstrap';
import ShippingAddressScreen from './screens/ShippingAddressScreen';
import SignupScreen from './screens/SignupScreen';
import PaymentMethodScreen from './screens/PaymentMethodScreen';
import PlaceOrderScreen from './screens/PlaceOrderScreen';
import OrderScreen from './screens/OrderScreen';
import OrderHistoryScreen from './screens/OrderHistoryScreen';
import ProfileScreen from './screens/ProfileScreen';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import { getError } from './utils';
import SearchBox from './components/SearchBox';
import SearchScreen from './screens/SearchScreen';
import ProtectedRoute from './components/ProtectedRoute';
import AdminRoute from './components/AdminRoute';
import DashboardScreen from './screens/DashboardScreen';
import ProductListScreen from './screens/ProductListScreen';
import ProductEditScreen from './screens/ProductEditScreen';
import OrderListScreen from './screens/OrderListScreen';
import UserListScreen from './screens/UserListScreen';
import UserEditScreen from './screens/UserEditScreen';
import MapScreen from './screens/MapScreen';
import SellerScreen from './screens/SellerScreen';
import SellerRoute from './components/SellerRoute';
import SupportScreen from './screens/SupportScreen';
import ChatBox from './components/ChatBox';
import WholesaleScreen from './screens/WholesaleScreen';


function App() {
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { fullBox, cart, userInfo } = state;

  const signoutHandler = () => {
    ctxDispatch({ type: 'USER_SIGNOUT' });
    localStorage.removeItem('userInfo');
    localStorage.removeItem('shippingAddress');
    localStorage.removeItem('paymentMethod');
    window.location.href = '/signin';
  };

  const [sidebarIsOpen, setSidebarIsOpen] = useState(false);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const { data } = await axios.get(`https://supam-off-be.onrender.com/api/products/categories`);
        setCategories(data);
      } catch (err) {
        toast.error(getError(err));
      }
    };
    fetchCategories();
  }, []);

  const myLocation = useLocation().pathname

  //  console.log('cyphoned location',myLocation)

  return (
    <div className={sidebarIsOpen
      ? fullBox
        ? 'site-container active-cont d-flex flex-column full-box'
        : 'site-container active-cont d-flex flex-column'
      : fullBox
        ? 'site-container d-flex flex-column full-box'
        : 'site-container d-flex flex-column'
    }>
      <ToastContainer position="bottom-center" limit={1} />
      <header>
        {/* expand='lg' extends screensize while Navbar.Toggle and Navbar.Collapse makes it responsive */}
        <Navbar className="navbar navbar-expand-lg bg-dark navbar-dark" expand='lg'>

          <Container>

            <Button className='sbtn'
              variant="dark"
              onClick={() => setSidebarIsOpen(!sidebarIsOpen)}>
              <i className="fas fa-bars"></i>
            </Button>



            <LinkContainer to="/">
              <Navbar.Brand className="navbar-brand fs-4 fw-bold" >
                <img className='logo' src='/Assets/images/logo.png' alt='' />
                <span className='shopname'>&nbsp; &nbsp; E-SHOP</span>
              </Navbar.Brand>
            </LinkContainer>


            <Navbar.Toggle aria-controls="basic-navbar-nav" />

            <Navbar.Collapse id="basic-navbar-nav">
              <SearchBox />


              <Nav className='me-auto w-100 justify-content-end'>
              {myLocation === '/wholesale-market'
                ?
                (<Nav className='w-50'>
                <Link to='/' className='nav-link' >Retail Market</Link>
               </Nav>)
                :
                (<Nav className='w-50'>
                  <Link to='/wholesale-market' className='nav-link'>Wholesale</Link>
                 </Nav>)
              }

                <Link to="/cart" className='nav-link'>
                  Cart
                  {cart.cartItems.length > 0 && (
                    <Badge pill bg='danger'> {cart.cartItems.reduce((a, c) => a + c.quantity, 0)}</Badge>
                  )}
                </Link>


                {userInfo ? (
                  <NavDropdown title={userInfo.name} id="basic-nav-dropdown">
                    <LinkContainer to="/profile">
                      <NavDropdown.Item>User Profile</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/orderhistory">
                      <NavDropdown.Item>Order History</NavDropdown.Item>
                    </LinkContainer>
                    <NavDropdown.Divider />
                    <Link
                      className="dropdown-item"
                      to="#signout"
                      onClick={signoutHandler}
                    >
                      Sign Out
                    </Link>
                  </NavDropdown>
                ) : (
                  <Link className="nav-link" to="/signin">
                    Sign In
                  </Link>
                )}
                {userInfo && userInfo.isSeller && (
                  <NavDropdown title="Seller" id="basic-nav-dropdown">
                    <LinkContainer to={`/seller/${userInfo._id}`}>
                      <NavDropdown.Item>Seller Dashboard</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/productlist/seller">
                      <NavDropdown.Item>Products</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/orderlist/seller">
                      <NavDropdown.Item>Orders</NavDropdown.Item>
                    </LinkContainer>
                  </NavDropdown>
                )}
                {userInfo && userInfo.isAdmin && (
                  <NavDropdown title="Admin" id="admin-nav-dropdown">
                    <LinkContainer to="/admin/dashboard">
                      <NavDropdown.Item>Dashboard</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/productlist">
                      <NavDropdown.Item>Products</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/orderlist">
                      <NavDropdown.Item>Orders</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/userlist">
                      <NavDropdown.Item>Users</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/support">
                      <NavDropdown.Item>Support</NavDropdown.Item>
                    </LinkContainer>

                  </NavDropdown>
                )}

              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>

      <div
        className={
          sidebarIsOpen
            ? 'active-nav side-navbar d-flex justify-content-between flex-wrap flex-column'
            : 'side-navbar d-flex justify-content-between flex-wrap flex-column'
        }
      >
        <Nav className="flex-column text-white w-100 p-2">
          <Nav.Item> <strong>Categories</strong></Nav.Item>
          {categories.map((category) => (
            <Nav.Item key={category}>
              <LinkContainer to={`/search?category=${category}`} onClick={() => setSidebarIsOpen(false)} >
                <Nav.Link>{category}</Nav.Link>
              </LinkContainer>
            </Nav.Item>
          ))}
        </Nav>
      </div>





      <main>
        <Container className='mt-3'>
          <Routes>
{/* SELLER */}
            <Route path="/seller/:id" element={<SellerRoute><SellerScreen /></SellerRoute>} />
            <Route path="/productlist/seller" element={<SellerRoute> <ProductListScreen /> </SellerRoute>} />
            <Route path="/orderlist/seller" element={<SellerRoute> <OrderListScreen /> </SellerRoute>} />
{/* ADMIN */}
            <Route path='/admin/dashboard' element={<AdminRoute><DashboardScreen /></AdminRoute>} />
            <Route path='/orderlist' element={<AdminRoute><OrderListScreen /></AdminRoute>} />
            <Route path='/userlist' element={<AdminRoute><UserListScreen /></AdminRoute>} />
            <Route path='/admin/user/:id' element={<AdminRoute><UserEditScreen /></AdminRoute>} />
            <Route path='/productlist' element={<AdminRoute><ProductListScreen /></AdminRoute>} />
            <Route path='/products/pageNumber/:pageNumber' element={<AdminRoute><ProductListScreen /></AdminRoute>} />
            <Route path='/product/:id/edit' element={<SellerRoute><ProductEditScreen /></SellerRoute>} />
            <Route path="/support" element={<AdminRoute><SupportScreen /> </AdminRoute>} />

            <Route path='/' element={<HomeScreen />} />
            <Route path='/wholesale-market' element={<WholesaleScreen />} />
{/* USER */}
            <Route path='/signin' element={<SigninScreen />} />
            <Route path='/signup' element={<SignupScreen />} />
            <Route path='/profile' element={<ProtectedRoute> <ProfileScreen /></ProtectedRoute>} />
            <Route path='/orderhistory' element={<ProtectedRoute><OrderHistoryScreen /></ProtectedRoute>} />

            <Route path='/product/:slug' element={<ProductScreen />} />

            <Route path='/cart' element={<CartScreen />} />
            <Route path="/cart/:id" element={<CartScreen />}></Route>

            <Route path='/search' element={<SearchScreen />} />
            <Route path='/shipping' element={<ShippingAddressScreen />} />
            <Route path='/payment' element={<PaymentMethodScreen />} />
            <Route path='/map' element={<ProtectedRoute><MapScreen /></ProtectedRoute>} />
            <Route path='/placeorder' element={<PlaceOrderScreen />} />
            <Route path='/order/:id' element={<ProtectedRoute><OrderScreen /></ProtectedRoute>} />
          </Routes>
        </Container>
      </main>






      <footer>
        <div>
          <div className="footer h-23 clearfix text-white" style={{ "backgroundColor": '#34Ff23' }}></div>
          <nav className="navbar navbar-expand-lg bg-dark navbar-dark text-white d-flex justify-content-around ">
            {userInfo && !userInfo.isAdmin && <ChatBox userInfo={userInfo} />}

            <div>© SUPAMART GHANA Copyright 2022. All Rights Reserved </div>
            <div>Powered by  &nbsp;<a style={{ color: 'yellow', textDecoration: 'none' }} href="https://superjcybs.com">SUPERJCYBS</a></div>
            <span ><a className='px-3 py-1' href='https://www.facebook.com/superjcybs' style={{ backgroundColor: 'white', color: 'blue', fontWeight: 'bold', borderRadius: '50%', fontSize: '25px', width: '30px', height: '30px', textDecoration: 'none' }}>f</a></span>
          </nav>
        </div>
      </footer>
    </div>
  );
}

export default App;