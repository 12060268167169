import React, { useEffect, useRef, useState } from 'react';
import socketIOClient from 'socket.io-client';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import ListGroup from 'react-bootstrap/ListGroup';

const ENDPOINT =
  window.location.host.indexOf('localhost') >= 0
    // ? 'http://127.0.0.1:7000'
      ? 'https://supam-off-be.onrender.com'
    : window.location.host;

export default function ChatBox(props) {
  const { userInfo } = props;
  const [socket, setSocket] = useState(null);
  const uiMessagesRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [messageBody, setMessageBody] = useState('');
  const [messages, setMessages] = useState([
    { name: 'Admin', body: 'Hi there, Please what do you want to enquire about?' },
  ]);

  useEffect(() => {
    if (uiMessagesRef.current) {
      uiMessagesRef.current.scrollBy({
        top: uiMessagesRef.current.clientHeight,
        left: 0,
        behavior: 'smooth',
      });
    }
    if (socket) {
      socket.emit('onLogin', {
        _id: userInfo._id,
        name: userInfo.name,
        isAdmin: userInfo.isAdmin,
      });
      socket.on('message', (data) => {
        console.log('fromChatBox', data)
        setMessages([...messages, { body: data.body, name: data.name }]);
      });
    }
  }, [messages, isOpen, socket, userInfo]);

  const supportHandler = () => {
    setIsOpen(true);
    console.log(ENDPOINT);
    const sk = socketIOClient(ENDPOINT);
    setSocket(sk);
  };
  const submitHandler = (e) => {
    e.preventDefault();
    if (!messageBody.trim()) {
      alert('Error. Please type message.');
    } else {
      setMessages([...messages, { body: messageBody, name: userInfo.name }]);
      setMessageBody('');
      setTimeout(() => {
        socket.emit('onMessage', {
          body: messageBody,
          name: userInfo.name,
          isAdmin: userInfo.isAdmin,
          _id: userInfo._id,
        });
      }, 1000);
    }
  };
  const closeHandler = () => {
    setIsOpen(false);
  };
  return (
    <div className="chatbox">
      {!isOpen ? (
        <Button variant="light" type="button" onClick={supportHandler}>
          <i className="fas fa-comments" />
          {/* <i className="fas fa-users" /> */}
          {/* <i className="fas fa-life-ring" /> */}
        </Button>
      ) : (
        <Card>
          <Card.Body>
              <Row className="bg-success py-2 justify-content-between align-items-center">
                <Col className="text-light">
                <strong >Support </strong>
              </Col>
              <Col className="text-end">
                <Button variant="" type="button" onClick={closeHandler}>
                  <i className="fas fa-times text-light"></i>
                </Button>
              </Col>
            </Row>
            <ListGroup ref={uiMessagesRef}>
                {messages.map((msg, index) => (
               <ListGroup.Item key={index} className='no-border'>
                    <span className={userInfo.isAdmin === true || msg.name === 'Admin' ? 'customer' : 'sender'}>
                   {msg.body}
                </span>
                    </ListGroup.Item>
              ))}
            </ListGroup>
            <div>
              <form onSubmit={submitHandler}>
                <InputGroup>
                  <FormControl
                      type="text"
                      placeholder="Message"
                    id="q"
                    value={messageBody}
                    onChange={(e) => setMessageBody(e.target.value)}
                    required
                  />
                  <Button
                    variant="outline-primary"
                    type="submit"
                    id="button-search"
                  >
                    Send
                  </Button>
                </InputGroup>
              </form>
            </div>
          </Card.Body>
        </Card>
      )}
    </div>
  );
}
