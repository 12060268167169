import React, { useEffect, useReducer } from 'react'
import axios from 'axios';
// import logger from 'use-reducer-logger';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Product from '../components/Product';
import { Helmet } from 'react-helmet-async';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import { getError } from '../utils';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { Link } from 'react-router-dom';

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        products: action.payload.products,
        sellers: action.payload.sellers,
        loading: false
      };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

function WholesaleScreen() {

  const [{ loading, error, products, sellers }, dispatch] = useReducer((reducer), {
    // products: [],
    loading: true,
    error: '',
  });


  // useEffect(() => {
  //   const fetchData = async () => {
  //     dispatch({ type: 'FETCH_REQUEST' });
  //     try {
  //       const result = await axios.get('api/products');
  //       dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
  //     } catch (err) {
  //       dispatch({ type: 'FETCH_FAIL', payload: err.message });
  //     }
  //   };
  //   fetchData();
  // }, []);
  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: 'FETCH_REQUEST' });
      try {
        const { data: products } = await axios.get('https://supam-off-be.onrender.com/api/products/top-products');
        const { data: sellers } = await axios.get('https://supam-off-be.onrender.com/api/user/top-sellers');
        dispatch({ type: 'FETCH_SUCCESS', payload: { products, sellers } });
      } catch (error) {
        dispatch({
          type: 'FETCH_FAIL',
          payload: getError(error),
        });
      }
    };

    fetchData();
  }, [dispatch]);

console.log(sellers)

  return (
    <div>
      <Helmet>
        <title>Wholesale - SUPAMART GHANA</title>
      </Helmet>
        {loading ? (
          <LoadingBox/>
        )
          : error ? (
            <MessageBox variant="danger">{error}</MessageBox>
          ) :
            (
              <>
                {sellers.length === 0 && <MessageBox>No Seller Found</MessageBox>}
                <Carousel showArrows autoPlay showThumbs={false}>
                  {sellers.map((seller) => (
                    <div key={seller._id}>
                      <Link to={`/seller/${seller._id}`}>
                        <img src={seller.seller.logo} alt={seller.seller.name} />
                        <p className="legend">{seller.seller.name}</p>
                      </Link>
                    </div>
                  ))}
                </Carousel>
                <h2>Wholesale Market</h2>
                {products.length === 0 && <MessageBox>No Product Found</MessageBox>}

              <Row>
                {products.map((product) => (
                  <Col key={product.slug} sm={6} md={4} lg={3} className="mb-3">
                    <Product product={product}></Product>
                  </Col>
                ))}
                </Row>
                </>
            )}
      </div>
  )
}

export default WholesaleScreen


