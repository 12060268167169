import axios from 'axios';
import React, { useContext, useEffect, useReducer } from 'react';
import { PayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import { Store } from '../Store';
import { getError } from '../utils';
import { toast } from 'react-toastify';
/*-------------------------------------------------------- REDUCER FUNCTION ------------------------------------------------*/
function reducer(state, action) {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true, error: '' };
    case 'FETCH_SUCCESS':
      return { ...state, loading: false, order: action.payload, error: '' };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
         case 'PAY_REQUEST':
           return { ...state, loadingPay: true };
         case 'PAY_SUCCESS':
          return { ...state, loadingPay: false, successPay: true };
         case 'PAY_FAIL':
      return { ...state, loadingPay: false, errorPay: action.payload };
         case 'PAY_RESET':
      return { ...state, loadingPay: false, successPay: false, errorPay: '' };

        case 'DELIVER_REQUEST':
          return { ...state, loadingDeliver: true };
        case 'DELIVER_SUCCESS':
          return { ...state, loadingDeliver: false, successDeliver: true };
        case 'DELIVER_FAIL':
      return { ...state, loadingDeliver: false, errorDeliver: action.payload };
        case 'DELIVER_RESET':
          return {
            ...state,
            loadingDeliver: false,
            successDeliver: false,
            errorDeliver: '',
          };
    default:
      return state;
  }
}

export default function OrderScreen() {
  /*-------------------------------------------------------- USECONTEXT FUNCTION ------------------------------------------------*/
  const { state } = useContext(Store);
  const { userInfo } = state;

  /*-------------------------------------------------------- PARAMS & NAVIGATE ------------------------------------------------*/

  const params = useParams();
  const { id: orderId } = params;  // id here is being renamed to orderId hence the :
  const navigate = useNavigate();


  /*-------------------------------------------------------- USEREDUCER FUNCTION ------------------------------------------------*/

  const [{ loading, error, order, successPay, loadingPay, loadingDeliver, successDeliver,
  }, dispatch] = useReducer(reducer, {
    loading: true,
    order: {},
    error: '',
         successPay: false,
         loadingPay: false,
  });

      // THIS IS FROM THE paypal package.
     const [{ isPending }, paypalDispatch] = usePayPalScriptReducer();

      //implentation of createOrder
     function createOrder(data, actions) {
       return actions.order
         .create({
           purchase_units: [
             {
               amount: { value: order.totalPrice },
             },
           ],
         })
         .then((orderID) => {
           return orderID;
         });
     }

     // implement onApprove
     function onApprove(data, actions) {
       return actions.order.capture().then(async function (details) {
         try {
           dispatch({ type: 'PAY_REQUEST' });
           const { data } = await axios.put(`https://supam-off-be.onrender.com/api/orders/${order._id}/pay`, details,
             {
               headers: { authorization: `Bearer ${userInfo.token}` },
             }
           );
           dispatch({ type: 'PAY_SUCCESS', payload: data });
           toast.success('Payment successful');
         } catch (err) {
           dispatch({ type: 'PAY_FAIL', payload: getError(err) });
           toast.error(getError(err));
         }
       });
     }
     function onError(err) {
       toast.error(getError(err));
     }

  /*-------------------------------------------------------- USEEFFECT FUNCTION ------------------------------------------------*/

  // fetch order details from BE
  useEffect(() => {
    const fetchOrder = async () => {
      try {
        dispatch({ type: 'FETCH_REQUEST' });
        const { data } = await axios.get(`https://supam-off-be.onrender.com/api/orders/${orderId}`, {
          headers: { authorization: `Bearer ${userInfo.token}` },  // checks for user authorization
        });
        dispatch({ type: 'FETCH_SUCCESS', payload: data });
      } catch (err) {
        dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
      }
    };

    if (!userInfo) {
      return navigate('/login');
    }
    if (
      !order._id ||   successPay ||  successDeliver ||  (order._id && order._id !== orderId)
    ) {
      fetchOrder();
             if (successPay) {
               dispatch({ type: 'PAY_RESET' });
             }
            if (successDeliver) {
              dispatch({ type: 'DELIVER_RESET' });
            }
           } else {
             const loadPaypalScript = async () => {
               const { data: clientId } = await axios.get('https://supam-off-be.onrender.com/api/keys/paypal', {
                 headers: { authorization: `Bearer ${userInfo.token}` },
               });
               paypalDispatch({
                 type: 'resetOptions',
                 value: {
                   'client-id': clientId,
                   currency: 'USD',
                 },
               });
               paypalDispatch({ type: 'setLoadingStatus', value: 'pending' });
             };
             loadPaypalScript();
    }
  }, [order, userInfo, orderId, navigate, paypalDispatch, successPay, successDeliver,]);

    async function deliverOrderHandler() {
      try {
        dispatch({ type: 'DELIVER_REQUEST' });
        const { data } = await axios.put(
          `https://supam-off-be.onrender.com/api/orders/${order._id}/deliver`,
          {},
          {
            headers: { authorization: `Bearer ${userInfo.token}` },
          }
        );
        dispatch({ type: 'DELIVER_SUCCESS', payload: data });
        toast.success('Order is delivered');
      } catch (err) {
        toast.error(getError(err));
        dispatch({ type: 'DELIVER_FAIL' });
      }
    }

  return loading ? (
    <LoadingBox></LoadingBox>
  ) : error ? (
    <MessageBox variant="danger">{error}</MessageBox>
  ) : (
    <div>
      <Helmet>
        <title>Order {orderId.substring(0,8)} - SUPAMART</title>
      </Helmet>
      <h1 className="my-3">Order {orderId}</h1>

      <Row>
        <Col md={8}>
          {/* SHIPPING CARD/SECTION */}
          <Card className="mb-3">
            <Card.Body>
              <Card.Title>Shipping</Card.Title>
              <Card.Text>
                <strong>Name:</strong> {order.shippingAddress.fullName} <br />
                <strong>Address: </strong> {order.shippingAddress.address},
                {order.shippingAddress.city}, {order.shippingAddress.postalCode}
                ,{order.shippingAddress.country}
                &nbsp;
                {order.shippingAddress.location &&
                  order.shippingAddress.location.lat && (
                    <a
                      target="_new"
                      href={`https://maps.google.com?q=${order.shippingAddress.location.lat},${order.shippingAddress.location.lng}`}
                    >
                      Show On Map
                    </a>
                  )}
              </Card.Text>
              {order.isDelivered ? (
                <MessageBox variant="success">
                  Delivered at {order.deliveredAt}
                </MessageBox>
              ) : (
                <MessageBox variant="danger">Not Delivered</MessageBox>
              )}
            </Card.Body>
          </Card>
          {/* PAYMENT CARD/SECTION */}
          <Card className="mb-3">
            <Card.Body>
              <Card.Title>Payment</Card.Title>
              <Card.Text>
                <strong>Method:</strong> {order.paymentMethod}
              </Card.Text>
              {order.isPaid ? (
                <MessageBox variant="success">
                  Paid at {order.paidAt}
                </MessageBox>
              ) : (
                <MessageBox variant="danger">Not Paid</MessageBox>
              )}
            </Card.Body>
          </Card>
          {/* ORDER ITEMS CARD/SECTION */}
          <Card className="mb-3">
            <Card.Body>
              <Card.Title>Items</Card.Title>
              <ListGroup variant="flush">
                {order.orderItems.map((item) => (
                  <ListGroup.Item key={item._id}>

                    <Row className="align-items-center">
                      <Col md={6}>
                        <img
                          src={item.image}
                          alt={item.name}
                          className="img-fluid rounded img-thumbnail"
                        ></img>{' '}
                        <Link to={`/product/${item.slug}`}>{item.name}</Link>
                      </Col>
                      <Col md={3}>
                        <span>{item.quantity}</span>
                      </Col>
                      <Col md={3}>&cent; {item.price}</Col>
                    </Row>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          {/* ORDER SUMMARY               */}
          <Card className="mb-3">
            <Card.Body>
              <Card.Title>Order Summary</Card.Title>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <Row>
                    <Col>Items</Col>
                    <Col>&cent; {order.itemsPrice.toFixed(2)}</Col>
                  </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Row>
                    <Col>Shipping</Col>
                    <Col>&cent; {order.shippingPrice.toFixed(2)}</Col>
                  </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Row>
                    <Col>Tax</Col>
                    <Col>&cent; {order.taxPrice.toFixed(2)}</Col>
                  </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Row>
                    <Col>
                      <strong> Order Total</strong>
                    </Col>
                    <Col>
                      <strong>&cent; {order.totalPrice.toFixed(2)}</strong>
                    </Col>
                  </Row>
                </ListGroup.Item>
                {!order.isPaid && (
                  <ListGroup.Item>
                     {isPending ? (
                      <LoadingBox />
                    ) : (
                            <div>
                              {/* CREATEORDER:- executes when button is clicked, ONAPPROVE:- executes when payment is successful, ONERROR:- executes when payment fails */}
                        <PayPalButtons
                                createOrder={createOrder}                     
                                onApprove={onApprove}   
                                onError={onError}           
                        ></PayPalButtons>
                      </div>
                    )} 
                     {loadingPay && <LoadingBox></LoadingBox>} 
                  </ListGroup.Item>
                )}
                {userInfo.isAdmin && order.isPaid && !order.isDelivered && (
                  <ListGroup.Item>
                     {loadingDeliver && <LoadingBox></LoadingBox>} 
                    <div className="d-grid">
                      <Button type="button"
                       onClick={deliverOrderHandler}
                      >
                        Deliver Order
                      </Button>
                    </div>
                  </ListGroup.Item>
                )}
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}